import * as React from "react"
import Helmet from "react-helmet"
import { useTranslation } from "react-i18next"
import { useStaticQuery, graphql } from "gatsby"

type HelmetProps = {
  title?: string
}

export const MaphiHelmet = (props: HelmetProps) => {
  const { t, i18n } = useTranslation()
  const title = props.title ? `Maphi - ${props.title}` : t("site.globalTitle")

  const smPreview = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "landing/AdobeStock_57845683.jpeg" }) {
        childImageSharp {
          fixed(width: 1200) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <Helmet>
      <html lang={i18n.language} />
      <meta charSet="utf-8" />
      <meta name="description" content={t("site.description")} />
      <meta name="keywords" content={t("site.keywords")} />
      <meta name="author" content="Edutapps GmbH" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={t("site.description")} />
      <meta
        property="og:image"
        content={`https://maphi.app${smPreview.file.childImageSharp.fixed.src}`}
      />
      <title>{title}</title>
    </Helmet>
  )
}
