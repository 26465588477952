import i18next from "i18next"
import { initReactI18next } from "react-i18next"
import { defaultLocale } from "../constants/locales"

const getResources = (locale: string) => {
  switch (locale) {
    default:
    case "en":
      return { en: { translation: require("../../locales/en.json") } }
    case "de":
      return { de: { translation: require("../../locales/de.json") } }
    case "es":
      return { es: { translation: require("../../locales/es.json") } }
    case "fr":
      return { fr: { translation: require("../../locales/fr.json") } }
  }
}

export const initI18nextContext = (locale: string) => {
  i18next
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      resources: { ...getResources(defaultLocale), ...getResources(locale) },
      lng: locale,
      fallbackLng: defaultLocale,
      interpolation: {
        escapeValue: false,
      },
      compatibilityJSON: "v2",
    })

  return i18next
}
