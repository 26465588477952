import { PageContextProp } from "./PageContext"

export function getLocalizedPath(
  path?: string,
  locale?: string,
  params = new URLSearchParams(
    typeof document !== "undefined" ? document.location.search : undefined
  )
) {
  const paramsString = params.toString()
  return `/${locale || "en"}${path || "/"}${
    paramsString.length > 0 ? `?${paramsString}` : ""
  }`
}

export function getLocalizedPathFromContext(
  path: string,
  context: PageContextProp
) {
  return getLocalizedPath(path, context.locale)
}
