import * as React from "react"

export interface PageContextProp {
  locale?: string
  originalPath?: string
}

export interface PageContextProps {
  path: string
  pageContext: PageContextProp
}

export const PageContext = React.createContext<PageContextProp>({})

export const usePageContext = () => React.useContext(PageContext)
