import * as React from "react"
import { I18nextProvider } from "react-i18next"
import { i18n } from "i18next"
import { initI18nextContext } from "./i18next"
import { PageContext, PageContextProps } from "./PageContext"
import browserLang from "browser-lang"
import { navigate } from "gatsby"
import { getLocalizedPath } from "./getLocalizedRoute"

export const LocalizedPage = (props: {
  children: JSX.Element
  locale: string
  i18n: i18n
}) => {
  return <I18nextProvider i18n={props.i18n}>{props.children}</I18nextProvider>
}

export const localizedPage = (Component: React.ComponentType<any>) => (
  props: PageContextProps
) => {
  let pageContext = props.pageContext
  if (!pageContext || !pageContext.locale) {
    const language =
      typeof window !== "undefined"
        ? browserLang({
            languages: ["en", "de"],
            fallback: "en",
          })
        : "en"
    pageContext = {
      locale: language,
      originalPath: props.path,
    }
    if (language != "en") {
      navigate(getLocalizedPath(props.path, language))
    }
  }
  return (
    <PageContext.Provider value={pageContext}>
      <LocalizedPage
        i18n={initI18nextContext(pageContext.locale)}
        locale={pageContext.locale}
      >
        <Component {...props} />
      </LocalizedPage>
    </PageContext.Provider>
  )
}
