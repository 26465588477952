import Color from "color"

function toBase256RGB(r: number, g: number, b: number, a = 1.0) {
  const C = (v: number) => Math.floor(v * 256)
  return `rgba(${C(r)},${C(g)},${C(b)},${a})`
}

const palette = {
  ocean: "#295351",
  green: "#445c33",
}

export const colors = {
  dominant: {
    normal: palette.ocean,
    lighter: Color(palette.ocean)
      .lighten(0.5)
      .toString(),
    light: Color(palette.ocean)
      .lighten(0.2)
      .toString(),
    veryLight: Color(palette.ocean)
      .lighten(0.8)
      .toString(),
  },
  highlight: { normal: "#FE7500", strong: toBase256RGB(0.96, 0.32, 0, 1) },
  neutral: {
    veryLight: "#F2F2F2",
    light: "#CCCCCC",
    normal: "#888888",
    dark: "#333333",
  },
  green: { normal: palette.green },
  red: { normal: "#e8391e" },
}
