import * as React from "react"
import { colors } from "../layouts/colors"
import { sizes } from "../layouts/sizes"
import { FullWidthComponent } from "./FullWidthComponent"
import { Link } from "gatsby"

import "bootstrap/dist/css/bootstrap.min.css"
import { Row, Col } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { LocalizedLink } from "../i18n/LocalizedLink"

const linkStyle: React.CSSProperties = {
  color: "white",
  fontSize: "0.75em",
  margin: sizes.margins.normal,
}

const SiteLink = (props: { to: string; children: JSX.Element | string }) => {
  return (
    <Col xs="auto">
      <LocalizedLink style={linkStyle} to={props.to}>
        {props.children}
      </LocalizedLink>
    </Col>
  )
}

export const Footer = () => {
  const { t } = useTranslation()

  return (
    <div
      style={{
        width: "100%",
        backgroundColor: colors.dominant.normal,
        color: "white",
      }}
    >
      <FullWidthComponent
        style={{ padding: sizes.margins.normal, display: "flex" }}
      >
        <Row style={{ flex: 1, justifyContent: "center" }}>
          <SiteLink to="/legal-notice">{t("legalNotice.title")}</SiteLink>
          <SiteLink to="/privacy-policy">{t("privacyPolicy.title")}</SiteLink>
          <SiteLink to="/tos">{t("termsAndConditions.title")}</SiteLink>
          <SiteLink to="/open-source">{"Open-source"}</SiteLink>
        </Row>
      </FullWidthComponent>
    </div>
  )
}
