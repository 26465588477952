import * as React from "react"
import { sizes } from "../layouts/sizes"

interface FullWidthComponentProps {
  style?: React.CSSProperties
  centerStyle?: React.CSSProperties
  left?: JSX.Element
  right?: JSX.Element
}

export class FullWidthComponent extends React.Component<
  FullWidthComponentProps
> {
  render() {
    return (
      <div
        style={{
          overflowX: "hidden",
          display: "flex",
          width: "100%",
          ...this.props.style,
        }}
      >
        <div style={{ flex: 1 }}>{this.props.left}</div>
        <div
          style={{
            maxWidth: sizes.pageWidth,
            ...this.props.centerStyle,
          }}
        >
          {this.props.children}
        </div>
        <div style={{ flex: 1 }}>{this.props.right}</div>
      </div>
    )
  }
}
