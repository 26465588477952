export const sizes = {
  margins: {
    large: 20,
    normal: 10,
    small: 5,
    larger: 50,
    veryLarge: 100,
  },
  pageWidth: 800,
}
