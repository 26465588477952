import * as React from "react"
import Link from "gatsby-link"
import Img from "gatsby-image"
import { colors } from "../layouts/colors"
import { graphql, StaticQuery } from "gatsby"
import { sizes } from "../layouts/sizes"
import { useSpring, animated } from "react-spring"
import { useTranslation } from "react-i18next"
import { locales } from "../constants/locales"
import { LocalizedLink } from "../i18n/LocalizedLink"
import { getLocalizedPath } from "../i18n/getLocalizedRoute"
import { usePageContext } from "../i18n/PageContext"

const MaphiIcon = () => {
  return (
    <StaticQuery
      query={graphql`
        {
          file(relativePath: { eq: "AppIcon.png" }) {
            childImageSharp {
              fixed(width: 50, height: 50) {
                base64
                width
                height
                src
                srcSet
              }
            }
          }
        }
      `}
      render={data => (
        <Img
          fixed={data.file.childImageSharp.fixed}
          imgStyle={{ borderRadius: 15 }}
        />
      )}
    />
  )
}

const LanguageSelector = () => {
  const { i18n } = useTranslation()

  const context = usePageContext()
  const buttons: JSX.Element[] = []

  locales.forEach(lang => {
    const code = lang.locale
    if (i18n.language === code) {
      buttons.push(<div key={buttons.length}>{code.toUpperCase()}</div>)
    } else {
      buttons.push(
        <Link
          to={getLocalizedPath(context.originalPath, code)}
          style={{ color: colors.highlight.normal, textDecoration: "none" }}
          key={buttons.length}
          hrefLang={code}
        >
          {code.toUpperCase()}
        </Link>
      )
    }
    buttons.push(
      <div
        style={{
          height: "1.5em",
          width: 1,
          backgroundColor: "white",
          margin: "0.5em",
        }}
        key={buttons.length}
      />
    )
  })

  buttons.pop()

  return (
    <div
      style={{
        margin: 0,
        float: "right",
        alignSelf: "center",
        alignItems: "center",
        color: "white",
        fontFamily: "sans-serif",
        display: "flex",
      }}
    >
      {buttons}
    </div>
  )
}

const FadedBackground = (props: {
  visible: boolean
  children: JSX.Element
}) => {
  const targetColor = props.visible ? colors.dominant.normal : "rgba(0,0,0,0)"
  const style = useSpring({
    backgroundColor: targetColor,
    from: { backgroundColor: targetColor },
  })
  return <animated.div style={style}>{props.children}</animated.div>
}

export class Header extends React.Component<{
  style?: React.CSSProperties
  transparent?: boolean
}> {
  render() {
    return (
      <div style={this.props.style}>
        <FadedBackground visible={!this.props.transparent}>
          <div
            style={{
              margin: "0 auto",
              maxWidth: sizes.pageWidth,
              padding: `${sizes.margins.normal}pt`,
              display: "flex",
            }}
          >
            <span
              style={{
                margin: 0,
                color: "white",
                fontWeight: 300,
                fontSize: "2.25rem",
                textShadow: "0px 1px 2px rgba(0,0,0,0.5)",
              }}
            >
              <LocalizedLink
                to="/"
                style={{
                  alignItems: "center",
                  color: "inherit",
                  display: "flex",
                  justifyContent: "left",
                  textDecoration: "none",
                }}
              >
                <MaphiIcon />
                <p style={{ width: sizes.margins.normal }} />
                Maphi
              </LocalizedLink>
            </span>
            <div style={{ flex: 1 }} />
            <LanguageSelector />
          </div>
        </FadedBackground>
      </div>
    )
  }
}
